<script setup lang="ts">
import type { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import type { CalloutVariant } from '~/utils/callout'
import { KsIcon } from '@aschehoug/kloss'
import { faXmark } from '@fortawesome/pro-regular-svg-icons'
import { faCircleInfo, faHexagonCheck, faOctagonXmark, faSealExclamation } from '@fortawesome/pro-solid-svg-icons'
import { ref } from 'vue'

const props = defineProps<{
  variant: CalloutVariant
  title?: string
  dismissable?: boolean
}>()

const emit = defineEmits<{
  (event: 'close'): void
}>()

const open = ref(true)

const VARIANTS = {
  info: {
    icon: faCircleInfo,
    label: 'Melding, info',
    color: 'text-blue-500',
    backgroundColor: 'bg-blue-50',
  },
  success: {
    icon: faHexagonCheck,
    label: 'Melding, suksess',
    color: 'text-green-500',
    backgroundColor: 'bg-green-50',
  },
  warning: {
    icon: faSealExclamation,
    label: 'Melding, info',
    color: 'text-yellow-500',
    backgroundColor: 'bg-yellow-50',
  },
  error: {
    icon: faOctagonXmark,
    label: 'Melding, feil',
    color: 'text-red-500',
    backgroundColor: 'bg-red-50',
  },
} as const satisfies Record<CalloutVariant, { icon: IconDefinition, color: string, label: string, backgroundColor: string }>

const v = computed(() => VARIANTS[props.variant])

onMounted(() => {
  const callout = document.querySelector('[role="alert"]') as HTMLElement
  if (callout) {
    callout.focus()
  }
})

function onClose({ target }: MouseEvent) {
  const button = target as HTMLElement
  const callout = button.parentNode as HTMLElement
  const nextElement = callout.nextElementSibling as HTMLElement | null
  open.value = false
  emit('close')
  nextElement?.focus()
}
</script>

<template>
  <aside v-if="open" class="relative text-carbon-500 p-s gap-s rounded-lg" :aria-label="v.label" :class="v.backgroundColor" role="alert" aria-live="assertive" tabindex="-1">
    <KsIcon class="absolute mr-s left-5 top-[1.125rem]" :icon="v.icon" :class="v.color" />
    <div class="px-9 flex flex-col items-start justify-center">
      <p v-if="title" class="text-lg font-medium" v-text="title" />
      <slot aria-live="polite" />
    </div>
    <Button v-if="dismissable" :icon="faXmark" class="absolute top-2 right-2 !bg-transparent !text-blurple-500" shape="rounded" aria-label="Lukk melding" title="Lukk melding" @click="onClose" />
  </aside>
</template>
